// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-icons/font/bootstrap-icons.css';

// Variables
@import 'variables';

// Utilities
@import 'utilities';

// Font Awesome
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';


// datatables
@import '~datatables.net-bs5/css/dataTables.bootstrap5.css';
@import '~datatables.net-buttons-bs5/css/buttons.bootstrap5.css';

// Tempus Dominus
// @import '~tempusdominus-bootstrap-4/src/sass/tempusdominus-bootstrap-4-build';

// summernote
// @import '~summernote/dist/summernote-bs5.min';

// @import '~bootstrap-fileinput/css/fileinput.min.css';

@import '~bootstrap-select/sass/bootstrap-select';

@import '~croppie/croppie.css';

@import '~flatpickr/dist/themes/airbnb.css';

@import "~flatpickr/dist/plugins/monthSelect/style.css";
